'use strict'

import Vue from 'vue'
import axios from 'axios'
import store from '../store'
// import { ADD_COUNTER_REQUEST, SUBTRACT_COUNTER_REQUEST } from '../store/mutations-types'

/**
 * File to configure the axios library as a Vue plugin.
 *
 * @author C Camilo Castellanos
 * @version 1.0.0
 * @since 19/12/2019 11:19 p. m.
 */

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

/* Axios global configuration  */
let config = {
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    common: {
      /* Header authentication */
      [process.env.VUE_APP_API_TOKEN_HEADER]: `${process.env.VUE_APP_API_TOKEN_PREFIX} ${store.state.security.token}`
    }
  }
}

/* Axios instance with the new configuration. */
const _axios = axios.create(config)

/* Add a request interceptor. */
_axios.interceptors.request.use(
  /**
   * Function to intercept the request before sending.
   *
   * @author C Camilo Castellanos
   * @version 1.0.0
   * @since 19/12/2019 11:27 p. m.
   */
  function (config) {
    /* Show spinner loader */
    // store.commit(ADD_COUNTER_REQUEST)
    return config
  },
  /**
   * Function to intercept the error request before sending.
   *
   * @author C Camilo Castellanos
   * @version 1.0.0
   * @since 19/12/2019 11:29 p. m.
   */
  function (error) {
    /* Hide spinner loader */
    // store.commit(SUBTRACT_COUNTER_REQUEST)
    return Promise.reject(error)
  }
)

/* Add a response interceptor. */
_axios.interceptors.response.use(
  /**
   * Function to intercept the Rest API response before sending to Vue Component.
   *
   * @author C Camilo Castellanos
   * @version 1.0.0
   * @since 19/12/2019 11:33 p. m.
   */
  function (response) {
    /* Hide spinner loader */
  // store.commit(SUBTRACT_COUNTER_REQUEST)
    return response
  },
  /**
   * Function to intercept the Rest API error response before sending to Vue Component.
   *
   * @author C Camilo Castellanos
   * @version 1.0.0
   * @since 19/12/2019 11:33 p. m.
   */
  function (error) {
    /* Hide spinner loader */
    // store.commit(SUBTRACT_COUNTER_REQUEST)
    return Promise.reject(error)
  }
)

/**
 * Install axios library as Vue plugin.
 *
 * @param Vue
 * @param options
 * @returns {AxiosInstance}
 *
 * @author C Camilo Castellanos
 * @version 1.0.0
 * @since 19/12/2019 11:35 p. m.
 */
Plugin.install = function (Vue, options) {
  Vue.axios = _axios
  window.axios = _axios
  Object.defineProperties(Vue.prototype, {
    axios: {
      /**
       * Get axios instance from Vue context.
       *
       * @returns {AxiosInstance}
       *
       * @author C Camilo Castellanos
       * @since 12/02/2019 10:52 PM
       * @version 1.0.0
       */
      get () {
        return _axios
      }
    },
    $axios: {
      /**
       * Get axios instance from Vue context.
       *
       * @returns {AxiosInstance}
       *
       * @author C Camilo Castellanos
       * @since 12/02/2019 10:52 PM
       * @version 1.0.0
       */
      get () {
        return _axios
      }
    }
  })
}

Vue.use(Plugin)

export default Plugin
