'use strict'

/**
 * File with methods of the mutations to modify states
 *
 * @type {number}
 *
 * @author C Camilo Castellanos
 * @version 1.0.0
 * @since 19/12/2019 9:49 p. m.
 */

import { ADD_COUNTER_REQUEST, HIDE_ALERT, SHOW_ALERT, SUBTRACT_COUNTER_REQUEST } from './mutations-types'

export default {
  /**
   * Function to hide alert
   *
   * @param state, message
   *
   * @author C Camilo Castellanos
   * @version 1.0.0
   * @since 19/12/2019 9:50 p. m.
   */
  [HIDE_ALERT]: state => {
    state.alert = {
      type: null,
      text: null,
      show: false
    }
  },
  /**
   * Function to show alert
   *
   * @param state, message
   *
   * @author C Camilo Castellanos
   * @version 1.0.0
   * @since 19/12/2019 9:52 p. m.
   */
  [SHOW_ALERT]: (state, message) => {
    state.alert = {
      type: message.type,
      text: message.text,
      show: true
    }
  },
  /**
   * Function to increase the request counter to display spinner loader.
   *
   * @param state
   * @param showLoader
   *
   * @author C Camilo Castellanos
   * @version 1.0.0
   * @since 19/12/2019 9:54 p. m.
   */
  [ADD_COUNTER_REQUEST]: (state) => {
    state.requestCounterForSpinnerShown++
  },
  /**
   * Function to decrease the request counter to hide spinner loader.
   *
   * @param state
   *
   * @author C Camilo Castellanos
   * @version 1.0.0
   * @since 19/12/2019 9:56 p. m.
   */
  [SUBTRACT_COUNTER_REQUEST]: (state) => {
    state.requestCounterForSpinnerShown--
  }
}
