<template>
  <div class="b-spinner">
    <div class="b-spinner__loader">
      <div class="b-spinner__bounce b-spinner__bounce--one" />
      <div class="b-spinner__bounce b-spinner__bounce--two" />
      <div class="b-spinner__bounce b-spinner__bounce--three" />
    </div>
  </div>
</template>

<script>

/**
 * Component that represent the spinner loader.
 *
 * @author C Camilo Castellanos
 * @version 1.0.0
 * @since 18/12/2019 10:01 a. m.
 */
export default {
  name: 'TheSpinner'
}
</script>

<style lang="scss" scoped>
  .b-spinner {
    position: fixed;
    z-index: 1051;
    display: flex;
    align-items: center;
    width: 100vw;
    height: 100vh;
    min-height: 100vh;
    background-color: rgba(0,0,0,0.4);

    &__loader {
      margin: auto;

      & > .b-spinner__bounce {
        display: inline-block;
        width: 20px;
        height: 20px;
        border: 0.3em solid #fff;
        border-radius: 100%;
        background-color: transparent;
        animation: sk-bouncedelay 1.4s infinite ease-in-out both;

        &--one {
          animation-delay: -0.32s;
        }
        &--two {
          animation-delay: -0.16s;
        }
      }
    }

    @keyframes sk-bouncedelay {
      0%, 80%, 100% {
        transform: scale(0);
      } 40% {
        transform: scale(1.0);
      }
    }
  }
</style>
