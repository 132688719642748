'use strict'

/**
 * File with mutations used in Vuex
 *
 * @author C Camilo Castellanos
 * @version 1.0.0
 * @since 18/12/2019 10:29 p. m.
 */

import { LOGIN, LOGOUT, CONFIRM_CHANGE_PASSOWORD } from './mutations-types'

export default {
  [LOGIN]: (state, user) => {
    state.authenticated = true
    state.userInfo = user
    state.token = user.token
  },
  [LOGOUT]: (state) => {
    state.authenticated = false
    state.userInfo = {}
    state.token = null
  },
  [CONFIRM_CHANGE_PASSOWORD]: (state, change) => {
    state.userInfo.changePassword = change
  }
}
