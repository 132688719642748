'use strict'

/**
 * File with route configuration of the frontoffice authentication page.
 *
 * @author C Camilo Castellanos
 * @version 1.0.0
 * @since 19/12/2019 10:28 p. m.
 */

export default {
  path: 'frontoffice_page.authentication.route',
  component: () => import('../../components/layout/frontoffice/TheLayoutFrontoffice'),
  children: [
    {
      name: 'Authentication',
      path: '',
      component: () =>
        import('../../components/view/frontoffice/public/authentication/Authentication'),
      meta: {
        backoffice: false,
        title: 'frontoffice_page.authentication.title'
      }
    }
  ]
}
