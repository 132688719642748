'use strict'

/**
 * File with methods to get or manipulate the Vuex states
 *
 * @type {number}
 *
 * @author C Camilo Castellanos
 * @version 1.0.0
 * @since 19/12/2019 9:49 p. m.
 */

export default {
  /**
   * Get the Alert object to show or hide in the page.
   *
   * @author C Camilo Castellanos
   * @version 1.0.0
   * @since 20/12/2019 12:47 a. m.
   */
  getAlertObject: (state) => state.alert
}
