'use strict'

/**
 * Constant with commons methods of the mutations.
 *
 * @author C Camilo Castellanos
 * @version 1.0.0
 * @since 19/12/2019 9:48 p. m.
 */
export const ADD_COUNTER_REQUEST = 'addCounterRequest'
export const SUBTRACT_COUNTER_REQUEST = 'subtractCounterRequest'
export const SHOW_ALERT = 'showAlert'
export const HIDE_ALERT = 'hideAlert'
