'use strict'

import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import './registerServiceWorker'
import store from './store'
import i18n from './i18n'
import GlobalComponents from './globalComponents'

/* Plugin para validacion de campos de formularios */
import '@/plugins/vee-validate'

/* Configuracion de router para backoffice y frontoffice */
import backofficeRouter from './router/backoffice'
import frontofficeRouter from './router/frontoffice'
/* Import common styles to the application pages. */
import './assets/sass/app.scss'

import vuetify from './plugins/vuetify'

/* If the environment is equal to the development, then the application loads the sass file to identify the screen size */
if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'development-backoffice') {
  import('./assets/sass/material-responsive-test.scss').then(() => {
  })
}

/**
 * Main js file to initialize the Vue application.
 *
 * @type {boolean}
 *
 * @author C Camilo Castellanos
 * @version 1.0.0
 * @since 18/12/2019 8:30 a. m.
 */

/* Load the routes depending on the environment in which the platform runs. */
let router
switch (process.env.NODE_ENV) {
  case 'development-backoffice':
  case 'staging-backoffice':
  case 'production-backoffice':
    router = backofficeRouter
    break
  case 'development':
  case 'staging':
  case 'production':
  case 'qa':
    router = frontofficeRouter
    break
}

// Install components globally
Vue.use(GlobalComponents)
Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App)
}).$mount('#app')
