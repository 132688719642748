'use strict'

export default {
  path: 'frontoffice_page.project_bind_user.route',
  component: () => import('@/components/layout/frontoffice/TheLayoutFrontoffice'),
  children: [
    {
      name: 'ProjectBindUser',
      path: '',
      component: () =>
        import('@/components/view/frontoffice/private/projectBindUser/ProjectBindUser.vue'),
      meta: {
        backoffice: false,
        requiresAuthentication: true,
        profileCode: ['AD'],
        title: 'frontoffice_page.project_bind_user.title'
      }
    }
  ]
}
