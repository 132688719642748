'use strict'

import state from './state'
import getters from './getters'
import mutations from './mutations'

/**
 * File with Vuex configuration to security module.
 *
 * @author C Camilo Castellanos
 * @version 1.0.0
 * @since 18/12/2019 10:14 a. m.
 */
export default {
  state,
  getters,
  mutations
}
