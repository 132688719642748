'use strict'

/**
 * File with names of the mutations used in Vuex
 *
 * @author C Camilo Castellanos
 * @version 1.0.0
 * @since 18/12/2019 10:31 p. m.
 */

export const LOGIN = 'login'
export const LOGOUT = 'logout'
export const CONFIRM_CHANGE_PASSOWORD = 'confirmChangePassword'
