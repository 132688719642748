<template>
  <ValidationProvider
    v-slot="{ errors }"
    :name="label"
    :rules="rules"
    :vid="id"
  >
    <v-autocomplete
      :id="id"
      v-model="innerValue"
      :error-messages="errors"
      :label="labelInput"
      :name="name"
      :items="items"
      :item-text="itemText"
      :item-value="itemValue"
      :disabled="disabled"
      :clearable="clearable"
      background-color="white"
      filled
      outlined
      rounded
      v-on="$listeners"
    />
  </ValidationProvider>
</template>

<script>
/**
 * Component to select field with validation vee-validate
 *
 * @author Oscar Paredes
 * @version 1.0.0
 * @since 09/01/2020 06:20 a. m.
 */
export default {
  name: 'AppSelectWithValidation',
  props: {
    items: {
      type: Array,
      default: () => []
    },
    clearable: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    itemText: {
      type: String,
      default: ''
    },
    itemValue: {
      type: String,
      default: ''
    },
    id: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: ''
    },
    rules: {
      type: [Object, String],
      default: ''
    },
    value: {
      type: null,
      default: ''
    },
    labelInput: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    innerValue: ''
  }),
  watch: {
    /**
     * Handles internal model changes.
     *
     * @author Oscar Paredes
     * @version 1.0.0
     * @since 09/01/2020 06:20 a. m.
     */
    innerValue (newVal) {
      this.$emit('input', newVal)
    },
    /**
     * Handles external model changes.
     *
     * @author Oscar Paredes
     * @version 1.0.0
     * @since 09/01/2020 06:20 a. m.
     */
    value (newVal) {
      this.innerValue = newVal
    }
  },
  created () {
    if (this.value) {
      this.innerValue = this.value
    }
  }
}
</script>
