'use strict'

/**
 *
 *
 * @author Andres Correa
 * @version 1.0.0
 * @since 08/07/2020 12:57 pm.
 */

export default {
  path: 'frontoffice_page.register_new_user.route',
  component: () => import('@/components/layout/frontoffice/TheLayoutFrontoffice'),
  children: [
    {
      name: 'RegisterNewUser',
      path: '',
      component: () => import('@/components/view/frontoffice/public/registerNewUser/registerUser'),
      meta: {
        backoffice: false,
        requiresAuthentication: false,
        title: 'frontoffice_page.register_new_user.title'
      }
    },
    {
      name: 'setCode',
      path: 'frontoffice_page.register_new_user.set_code_qr.route',
      component: () =>
        import('@/components/view/frontoffice/public/registerNewUser/components/registerCode/RegisterCode'),
      props: true,
      meta: {
        backoffice: false,
        requiresAuthentication: false,
        title: 'frontoffice_page.register_new_user.set_code_qr.title'
      }
    }
  ]
}
