'use strict'

import Vue from 'vue'
import VueI18n from 'vue-i18n'

/**
 * File with i18n configuration.
 *
 * @author C Camilo Castellanos
 * @since 12/02/2019 10:12 PM
 * @version 1.0.0
 */
Vue.use(VueI18n)

/**
 * Function to load json files with translations in the locales folder
 *
 * @author C Camilo Castellanos
 * @version 1.0.0
 * @since 17/12/2019 11:01 p. m.
 */
function loadLocaleMessages () {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/\/([a-z0-9]+)\//i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = Object.assign(locales(key), messages[locale])
    }
  })
  return messages
}

/**
 * Create the new configuration of the i18n.
 *
 * @author C Camilo Castellanos
 * @version 1.0.0
 * @since 17/12/2019 11:04 p. m.
 */
export default new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || 'es',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'es',
  messages: loadLocaleMessages()
})
