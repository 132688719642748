'use strict'
import { AppTextFieldWithValidation, AppSelectWithValidation, AppCheckBoxWithValidation, AppRadioWithValidation } from './components/uiComponents'

/**
 * You can register global components here and use them as a plugin in your main Vue instance
 * Ideally, only small components that are re-used many times across your application should be registered here.
 * For plugins and bigger components local registration is preferable because it will allow you to do code splitting easier.
 *
 * @author C Camilo Castellanos
 * @version 1.0.0
 * @since 18/12/2019 9:34 a. m.
 */
const GlobalComponents = {
  install (Vue) {
    Vue.component(AppTextFieldWithValidation.name, AppTextFieldWithValidation)
    Vue.component(AppSelectWithValidation.name, AppSelectWithValidation)
    Vue.component(AppCheckBoxWithValidation.name, AppCheckBoxWithValidation)
    Vue.component(AppRadioWithValidation.name, AppRadioWithValidation)
  }
}

export default GlobalComponents
