'use strict'

/**
 *
 *
 * @author Andres Correa
 * @version 1.0.0
 * @since 15/05/2020 09:57 am.
 */

export default {
  path: 'frontoffice_page.confirm_change_password.route',
  component: () => import('../../components/layout/frontoffice/TheLayoutFrontoffice'),
  children: [
    {
      name: 'ConfirmChangePassword',
      path: '',
      component: () => import('../../components/view/frontoffice/private/confirmChangePassword/ConfirmChangePassword'),
      meta: {
        backoffice: false,
        requiresAuthentication: true,
        title: 'frontoffice_page.confirm_change_password.title'
      }
    }
  ]
}
