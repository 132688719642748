'use strict'

/**
 * File with different vuex states of the security module
 *
 * @author C Camilo Castellanos
 * @version 1.0.0
 * @since 18/12/2019 10:35 p. m.
 */

export default {
  authenticated: false,
  userInfo: {},
  token: null
}
