'use strict'

import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import es from 'vuetify/es5/locale/es'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: '#FFEF2D',
        secondary: '#2B2B2B',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#66A2F1',
        success: '#76CC14',
        warning: '#FFC107',
        green_button: '#76CC14',
        gray_color: '#707070'
      }
    }
  },
  lang: {
    locales: { es },
    current: 'es'
  },
  icons: {
    iconfont: 'fa'
  }
})
