'use strict'

/**
 * File with route configuration of the page not found.
 *
 * @author C Camilo Castellanos
 * @version 1.0.0
 * @since 19/12/2019 10:28 p. m.
 */
export default {
  path: '*',
  component: () => import('../components/view/PageNotFound')
}
