<template>
  <ValidationProvider
    v-slot="{ errors }"
    :rules="rules"
    :name="label"
    :vid="id"
  >
    <v-radio-group
      :id="id"
      v-model="innerValue"
      :error-messages="errors"
      :name="name"
      :label="labelInput"
    >
      <v-radio
        v-for="item in items"
        :key="item.code || item"
        :label="item.description || item"
        :value="item.code || item"
      />
    </v-radio-group>
  </ValidationProvider>
</template>

<script>
/**
 * Component to check field with validation vee-validate
 *
 * @author Oscar Paredes
 * @version 1.0.0
 * @since 09/01/2020 06:20 a. m.
 */
export default {
  name: 'AppRadioWithValidation',
  props: {
    id: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: ''
    },
    rules: {
      type: [Object, String],
      default: ''
    },
    items: {
      type: Array,
      default: () => []
    },
    value: {
      type: null,
      default: ''
    },
    labelInput: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    innerValue: ''
  }),
  watch: {
    /**
     * Handles internal model changes.
     *
     * @author Oscar Paredes
     * @version 1.0.0
     * @since 09/01/2020 06:20 a. m.
     */
    innerValue (newVal) {
      this.$emit('input', newVal)
    },
    /**
     * Handles external model changes.
     *
     * @author Oscar Paredes
     * @version 1.0.0
     * @since 09/01/2020 06:20 a. m.
     */
    value (newVal) {
      this.innerValue = newVal
    }
  },
  created () {
    if (this.value) {
      this.innerValue = this.value
    }
  }
}
</script>
