'use strict'

import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../../store'

import Authentication from './authentication'
import Home from './home'
import PageNotFound from '../pageNotFound'
import { afterEach, defaultBackofficeRoute, translateRoute } from '../routeUtility'
import { HIDE_ALERT } from '../../store/mutations-types'

/**
 * File to configuration all backoffice routes of the application
 *
 * @author C Camilo Castellanos
 * @version 1.0.0
 * @since 19/12/2019 10:24 p. m.
 */

const routes = [
  Authentication,
  Home,
  PageNotFound,
  defaultBackofficeRoute
].map(translateRoute)

Vue.use(VueRouter)

/* Configure router */
const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_BASE_URL,
  routes
})

/**
 * Befor loading the page, this function validates the following conditions:
 * 1. The user is authenticated
 * 2. The page does not requier authentication
 *
 * @author C Camilo Castellanos
 * @version 1.0.0
 * @since 19/12/2019 10:53 p. m.
 */
router.beforeEach((to, from, next) => {
  let page = null
  const homePage = {
    name: 'Home'
  }
  const authenticated = store.state.security.authenticated
  const requiresAuthentication = to.meta.requiresAuthentication
  if (to.name) {
    if (requiresAuthentication && !authenticated) {
      page = {
        name: 'Authentication'
      }
    } else if (!requiresAuthentication && authenticated) {
      page = homePage
    } // pending validate if application page requires permissions
  }

  if (!to.meta || !to.meta.alwaysVisibleAlert) {
    store.commit(HIDE_ALERT)
  }

  page ? next(page) : next()
})

router.afterEach(afterEach)

export default router
