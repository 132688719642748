'use strict'

/**
 * File with route configuration of the frontoffice projects page.
 *
 * @author Juan P. Bolivar
 * @version 1.0.0
 */

export default {
  path: 'frontoffice_page.projects.route',
  component: () => import('@/components/layout/frontoffice/TheLayoutFrontoffice'),
  children: [
    {
      name: 'projects',
      path: '',
      component: () =>
        import('@/components/view/frontoffice/private/projects/Projects.vue'),
      meta: {
        backoffice: false,
        requiresAuthentication: true,
        profileCode: ['SU', 'AD'],
        title: 'frontoffice_page.projects.title'
      }
    },
    {
      name: 'projectDetails',
      path: '/proyecto/:id',
      component: () =>
        import('@/components/view/frontoffice/private/projects/projectDetails/ProjectDetails.vue'),
      meta: {
        backoffice: false,
        requiresAuthentication: true,
        profileCode: ['PR'],
        title: 'frontoffice_page.projects.title'
      },
      params: ['id']
    }
  ]
}
