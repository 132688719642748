'use strict'

import { ValidationObserver, ValidationProvider, extend, localize, configure } from 'vee-validate'
import es from 'vee-validate/dist/locale/es.json'
import * as rules from 'vee-validate/dist/rules'
import Vue from 'vue'

/* Instalacion y configuracion Vee-validate */
Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule])
})
/**
 * Function to decimal validation
 *
 * @author Oscar Paredes
 * @version 1.0.0
 * @since 21/01/2020 09:25 AM
 */
extend('password_format', {
  validate: (value) => {
    if (value === null || value === undefined || value === '') {
      return {
        valid: false
      }
    }
    const regex = new RegExp(`^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})`)
    return {
      valid: regex.test(value),
      data: {
        serverMessage: 'Only decimal values are available'
      }
    }
  },
  message: 'El campo {_field_} debe contener una mayúscula, una minúscula, un número y un carácter especial (!@#$%^&*)'
})
/**
 * Valida que el archivo sea de extension pdf
 *
 * @author Juan P. Bolivar
 * @version 1.0.0
 */
extend('file-extensions', {
  validate: ({ name }, [allowExtensionsAlls]) => {
    const allowExtensions = allowExtensionsAlls.replace('-', '|')
    const regex = new RegExp(`^(.*)(\\.(${allowExtensions}))$`)
    return {
      valid: regex.test(name)
    }
  },
  message: 'El archivo cargado no cumple con la extensión indicada'
})
/**
 * Valida el nombre del archivo
 *
 * @author Juan P. Bolivar
 * @version 1.0.0
 */
extend('filename', {
  validate: ({ name }) => {
    const regex = new RegExp(`^(\\w|\\s|[á|Á]|[é|É]|[í|Í]|[ó|Ó]|[ú|Ú]|[ñ|Ñ]|-)+(\\.{1}(\\w)+)$`)
    return {
      valid: regex.test(name)
    }
  },
  message: 'El nombre del archivo debe estar compuesto únicamente por guiones y caracteres alfanumericos'
})

localize('es', es)
const config = {
  classes: {
    valid: 'is-valid',
    invalid: 'is-invalid'
  }
}
configure(config)

// Instalacionde componentes globales de vee-validate
Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)
