'use strict'

import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

/* Modules */
import security from './modules/security'
/* Configuration of the common files. */
import state from './state'
import mutations from './mutations'
import getters from './getters'
import SecureLS from 'secure-ls'
const ls = new SecureLS({
  isCompression: false,
  encodingType: process.env.VUE_APP_ENCODING_TYPE,
  encryptionSecret: process.env.VUE_APP_ENCRYPION_SECRET
})
/**
 * File with the configuration of the Vuex.
 *
 * @type {number}
 *
 * @author C Camilo Castellanos
 * @version 1.0.0
 * @since 18/12/2019 10:06 a. m.
 */
Vue.use(Vuex)

/*
* Code to create the Vuex instance and the logic to persist the states in the browser storage (LocalStorage by Default).
*/
export default new Vuex.Store({
  strict: true,
  modules: { security },
  plugins: [
    createPersistedState({
      key: `${process.env.VUE_APP_NAME}-vuex`,
      paths: ['security'],
      storage: {
        getItem: key => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: key => ls.remove(key)
      }
    })
  ],
  state,
  mutations,
  getters
})
