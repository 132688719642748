<template>
  <v-app
    id="app"
    class="b-app"
  >
    <the-spinner
      v-show="requestCounterForSpinnerShown > 0"
      ref="theSpinner"
    />
    <router-view />
  </v-app>
</template>
<script>
/**
 * App vue file management the rotues application.
 *
 * @author C Camilo Castellanos
 * @version 1.0.0
 * @since 18/12/2019 9:48 a. m.
 */
import TheSpinner from './components/layout/TheSpinner'
import { mapState } from 'vuex'

export default {
  name: 'App',
  components: {
    TheSpinner
  },
  computed: mapState(['requestCounterForSpinnerShown'])
}
</script>
<style lang="scss" scoped>
  .b-plantri-app {
    height: 100%;
  }
</style>
