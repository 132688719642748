'use strict'

import i18n from '../i18n'

/**
 * File with utilities function to routes management
 *
 * @author C Camilo Castellanos
 * @version 1.0.0
 * @since 19/12/2019 10:05 p. m.
 */

/**
 * Function to translate the all application routes.
 *
 * @param route
 * @returns {*}
 *
 * @author C Camilo Castellanos
 * @version 1.0.0
 * @since 19/12/2019 10:10 p. m.
 */
export function translateRoute (route) {
  if (route.path) {
    route.path = i18n.t(route.path)
  }
  if (route.redirect) {
    route.redirect = i18n.t(route.redirect)
  }
  if (route.meta && route.meta.title) {
    route.meta.title = i18n.t(route.meta.title)
  }
  if (route.children) {
    route.children = route.children.map(translateRoute)
  }
  return route
}

/**
 * Function for add page name to the title page shown in the browser tab.
 *
 * @param destino
 *
 * @author C Camilo Castellanos
 * @version 1.0.0
 * @since 19/12/2019 10:10 p. m.
 */
export const afterEach = (to) => {
  const pageTitle = to.meta && to.meta.title ? `| ${to.meta.title}` : ''
  document.title = `${process.env.VUE_APP_NAME} ${pageTitle}`
}

export const defaultFrontofficeRoute = {
  path: '/',
  redirect: 'frontoffice_page.home.route'
}

export const defaultBackofficeRoute = {
  path: '/',
  redirect: 'backoffice_page.home.route'
}
