'use strict'

/**
 *
 *
 * @author Andres Correa
 * @version 1.0.0
 * @since 15/05/2020 09:57 am.
 */

export default {
  path: 'frontoffice_page.projects_received_details.route',
  component: () => import('../../components/layout/frontoffice/TheLayoutFrontoffice'),
  children: [
    {
      name: 'ProjectsReceived',
      path: '',
      component: () => import('@/components/view/frontoffice/private/projects/projectsReceived/ProjectsReceived'),
      meta: {
        backoffice: false,
        requiresAuthentication: true,
        profileCode: ['SU', 'AD'],
        title: 'frontoffice_page.projects_received_details.title'
      },
      props: true
    },
    {
      name: 'ProjectFinishDetail',
      path: 'frontoffice_page.projects_received_details.details_proposal.route',
      component: () => import('@/components/view/frontoffice/private/projects/proposalFinishedDetail/ProposalFinishDetail'),
      meta: {
        backoffice: false,
        requiresAuthentication: true,
        profileCode: ['SU', 'AD'],
        title: 'frontoffice_page.projects_received_details.title'
      },
      props: true
    }
  ]
}
