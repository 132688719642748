'use strict'
/** librerias */
import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../../store'
/** utilidades */
import { afterEach, defaultFrontofficeRoute, translateRoute } from '../routeUtility'
import { HIDE_ALERT } from '../../store/mutations-types'
/** Rutas */
import Authentication from './authentication'
import Home from './home'
import Projects from './projects'
import PageNotFound from '../pageNotFound'
import ConfirmChangePassword from './recoverPassword'
import ProjectsReceived from './projectsReceived'
import DecryptFile from './public/decryptFile'
import RegisterNewUser from './registerUser'
import StagesConfiguration from './stagesConfiguration'
import ProjectBindUser from './projectBindUser'

/**
 * File to configuration all frontoffice routes of the application
 *
 * @author C Camilo Castellanos
 * @version 1.0.0
 * @since 19/12/2019 10:24 p. m.
 */

const routes = [
  Authentication,
  ConfirmChangePassword,
  Home,
  Projects,
  PageNotFound,
  ProjectsReceived,
  DecryptFile,
  defaultFrontofficeRoute,
  RegisterNewUser,
  StagesConfiguration,
  ProjectBindUser
].map(translateRoute)

Vue.use(VueRouter)

/* Configure router */
const router = new VueRouter({
  mode: 'history',
  base: process.env.VUE_APP_BASE_URL,
  routes
})

/**
 * Befor loading the page, this function validates the following conditions:
 * 1. The user is authenticated
 * 2. The page does not requier authentication
 *
 * @author C Camilo Castellanos
 * @version 1.0.0
 * @since 19/12/2019 10:53 p. m.
 */
router.beforeEach((to, from, next) => {
  let page = null
  const homePage = {
    name: 'Home'
  }
  const requiredChangePassword = store.state.security.userInfo.changePassword
  const authenticated = store.state.security.authenticated
  const requiresAuthentication = to.meta.requiresAuthentication
  const profileCode = store.state.security.userInfo.profileCode
  if (to.name) {
    if (requiresAuthentication && !authenticated && requiresAuthentication !== 'OP') {
      page = { name: 'Authentication' }
    } else if (requiresAuthentication !== 'OP' && (!requiresAuthentication && authenticated)) {
      page = homePage
    } else if (requiresAuthentication && authenticated && requiredChangePassword && to.name !== 'ConfirmChangePassword') {
      page = { name: 'ConfirmChangePassword' }
    } else if (to.meta.profileCode && profileCode && !to.meta.profileCode.includes(profileCode)) {
      page = homePage
    }
  }

  if (!to.meta || !to.meta.alwaysVisibleAlert) {
    store.commit(HIDE_ALERT)
  }

  page ? next(page) : next()
})

router.afterEach(afterEach)

export default router
