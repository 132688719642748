'use strict'

/**
 * File with route configuration of the backoffice home page.
 *
 * @author C Camilo Castellanos
 * @version 1.0.0
 * @since 19/12/2019 10:28 p. m.
 */

export default {
  path: 'backoffice_page.home.route',
  component: () => import('../../components/layout/backoffice/TheLayoutBackoffice'),
  children: [
    {
      name: 'Home',
      path: '',
      component: () => import('../../components/view/backoffice/private/home/Home'),
      meta: {
        backoffice: true,
        requiresAuthentication: true,
        title: 'backoffice_page.home.title'
      }
    }
  ]
}
