'use strict'

/**
 *
 *
 * @author Andres Correa
 * @version 1.0.0
 * @since 15/07/2020 11:08 am.
 */

export default {
  path: 'frontoffice_page.configuration_stages.route',
  component: () => import('@/components/layout/frontoffice/TheLayoutFrontoffice'),
  children: [
    {
      name: 'StagesConfiguration',
      path: '',
      component: () => import('@/components/view/frontoffice/private/projectSettings/StageSettings'),
      meta: {
        backoffice: false,
        requiresAuthentication: true,
        title: 'frontoffice_page.configuration_stages.title'
      },
      props: true
    },
    {
      name: 'SettingsSections',
      path: 'frontoffice_page.configuration_stages.config_section.route',
      component: () => import('@/components/view/frontoffice/private/projectSettings/projectSettingsSections/ProjectSettingsSections'),
      meta: {
        backoffice: false,
        requiresAuthentication: true,
        profileCode: ['AD'],
        title: 'frontoffice_page.configuration_stages.config_section.title'
      },
      props: true
    },
    {
      name: 'SettingsDocuments',
      path: 'frontoffice_page.configuration_stages.config_section.config_documents.route',
      component: () => import('@/components/view/frontoffice/private/projectSettings/projectSettingsDocuments/ProjectSettingsDocuments'),
      meta: {
        backoffice: false,
        requiresAuthentication: true,
        profileCode: ['AD'],
        title: 'frontoffice_page.configuration_stages.config_section.config_documents.title'
      },
      props: true
    }
  ]
}
